import React from 'react';
import styled from 'styled-components';
import palette from '../styles/palette';
import EquityCards from '../components/Equity/EquityCards';
import Posters from '../components/Poster/Posters';
const Portfolio = () => {
  return (
    <a name='portfolio'>
      <Background>
        <Header>Portfolio</Header>
        <Title>EQUITY INVESTMENT</Title>
        <EquityCards />
        <Title>PROJECT INVESTMENT</Title>
        <Posters />
      </Background>
    </a>
  );
};

export default Portfolio;

const Background = styled.div`
  background: ${palette.Black};
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding-bottom: 8rem;
`;

const Header = styled.div`
  margin: 5.7rem 0 0 5.7rem;
  font-family: 'Inter';
  font-weight: 300;
  color: white;
  font-size: 2.8rem;
  @media (max-width: 428px) {
    margin: 2.6rem 0 0 2rem;
    font-size: 1.8rem;
  }
`;

const Title = styled.div`
  font-family: 'Inter';
  font-weight: 300;
  font-size: 4.8rem;
  color: ${palette.orange};
  margin: 0 auto;
  text-align: center;
  margin-top: 13.4rem;
  width: 85%;
  @media (max-width: 428px) {
    font-size: 2.4rem;
    margin-top: 4.8rem;
  }
`;
