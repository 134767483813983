import React, { useState } from 'react';
import styled from 'styled-components';
import PosterItem from './PosterItem';
import PosterData from './PosterData';
import ViewMoreBtn from '../../assets/images/viewmore_btn.png';
import ViewLessBtn from '../../assets/images/viewless_btn.png';

const Posters = () => {
  const firstPageData = PosterData[0];
  const secondPageData = PosterData[1];
  const [data, setData] = useState(firstPageData);
  const [button, setButton] = useState(ViewMoreBtn);
  const viewMore = () => {
    setData(firstPageData.concat(secondPageData));
    setButton(ViewLessBtn);
  };
  const viewLess = () => {
    setData(firstPageData);
    setButton(ViewMoreBtn);
  };
  return (
    <>
      <Container>
        {data?.map((value, index) => (
          <PosterItem key={index} poster={value} />
        ))}
      </Container>
      {button === ViewMoreBtn ? (
        <ViewButton src={button} onClick={viewMore} alt='view more button' />
      ) : (
        <ViewButton src={button} onClick={viewLess} alt='view less button' />
      )}
    </>
  );
};

export default Posters;

const Container = styled.div`
  margin: 7.6rem auto 6rem auto;
  width: 125rem;
  display: grid;
  grid-template-columns: repeat(4, minmax(28.2rem, auto));
  gap: 3rem;
  @media (max-width: 480px) {
    width: 32rem;
    margin: 3rem auto;
    grid-template-columns: repeat(2, minmax(15.2rem, auto));
    gap: 1.6rem;
  }
`;
const ViewButton = styled.img`
  display: flex;
  margin: 0 auto;
  padding-bottom: 20rem;
  cursor: pointer;
  @media (max-width: 480px) {
    width: 15rem;
    padding-bottom: 6rem;
  }
`;
