import React from 'react';
import styled from 'styled-components';
import bar from '../assets/images/contact_orange.svg';
import { useMedia } from 'react-media';
const Contact = () => {
  const isMobile = useMedia({
    query: '(max-width:428px)',
  });

  return (
    <a name='contact'>
      <Background>
        <Header>Contact</Header>
        <Container>
          {isMobile ? (
            <iframe
              id='map'
              src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3164.1117652718544!2d126.9181330653106!3d37.528862579804944!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x357c9f24e971c81d%3A0xf3db46512386fdae!2z7ZWc7JaR67mM65Sp!5e0!3m2!1sko!2skr!4v1647749568189!5m2!1sko!2skr'
              width='100%'
              height='299'
              style={{ border: 0, borderRadius: '1rem' }}
              allowFullScreen=''
              loading='lazy'
            ></iframe>
          ) : (
            <iframe
              id='map'
              src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3164.1117652718544!2d126.9181330653106!3d37.528862579804944!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x357c9f24e971c81d%3A0xf3db46512386fdae!2z7ZWc7JaR67mM65Sp!5e0!3m2!1sko!2skr!4v1647749568189!5m2!1sko!2skr'
              width='943'
              height='456'
              style={{ border: 0, borderRadius: '1rem' }}
              allowFullScreen=''
              loading='lazy'
            ></iframe> 
          )}
          <Content>
            {isMobile ? (
              <img style={{ marginTop: '4rem' }} src={bar} alt='orange-bar' />
            ) : (
              <img style={{ marginTop: '24.7rem' }} src={bar} alt='orange-bar' />
            )}
            <Title>캐피탈원 주식회사</Title>
            <SubContent>
              서울특별시 영등포구 국회대로70길 18 <br />
              한양빌딩 405호
            </SubContent>
            <ContactTo>+82 2. 595. 7450</ContactTo>
            <ContactTo>admin@capitalonevc.co.kr</ContactTo>
          </Content>
        </Container>
      </Background>
    </a>
  );
};

export default Contact;

const Background = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding-bottom: 19.4rem;
  align-items: center;
  @media (max-width: 428px) {
    padding-bottom: 8rem;
  }
`;

const Header = styled.div`
  margin: 5.7rem 0 0 5.7rem;
  font-family: 'Inter';
  font-weight: 300;
  font-size: 2.8rem;
  text-align: left;
  width: 95%;
  @media (max-width: 428px) {
    margin: 2.6rem 0 0 2rem;
    font-size: 1.8rem;
  }
`;

const Container = styled.div`
  display: inline-flex;
  margin-top: 10.3rem;
  width: 68%;
  height: 57.6rem;
  align-items: center;
  @media (max-width: 428px) {
    margin-top: 3rem;
    display: flex;
    flex-direction: column;
    width: 88%;
    height: 100%;
  }
`;

const Content = styled.div`
  margin-left: 5.8rem;
  width: 64rem;
  font-weight: 500;
  text-align: left;
  @media (max-width: 428px) {
    margin-left: 0;
    width: 100%;
    padding-bottom: 2rem;
  }
`;
const Title = styled.div`
  margin-top: 1.2rem;
  padding-bottom: 4.5rem;
  font-size: 3.6rem;
  font-weight: 500;
  @media (max-width: 428px) {
    font-size: 2rem;
    margin-top: 0.5rem;
    padding-bottom: 0;
  }
`;
const SubContent = styled.div`
  font-size: 2.4rem;
  font-family: 'Spoqa Han Sans Neo';
  font-weight: 500;
  padding-bottom: 3.8rem;
  @media (max-width: 428px) {
    font-size: 1.4rem;
    margin-top: 2.5rem;
    padding-bottom: 2rem;
  }
`;

const ContactTo = styled.div`
  margin-top: 1rem;
  font-size: 2.4rem;
  font-family: 'Spoqa Han Sans Neo';
  font-weight: 500;
  @media (max-width: 428px) {
    font-size: 1.4rem;
    margin-top: 0.5rem;
  }
`;
