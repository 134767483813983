import React, { useCallback, useRef } from 'react';
import styled, { css } from 'styled-components';
import Slide from './SlideItem';
import slideData from './SlideData';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import leftBtnActive from '../../assets/images/left_btn_act.svg';
import rightBtnActive from '../../assets/images/right_btn_act.svg';
import palette from '../../styles/palette';

const Slides = () => {
  const data = slideData;
  const slickRef = useRef(null);
  const previous = useCallback(() => slickRef.current.slickPrev(), []);
  const next = useCallback(() => slickRef.current.slickNext(), []);
  const settings = {
    dots: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    initialSlide: 0,
    arrows: false,
    infinite: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinte: true,
          dots: true,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          intitialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          intitialSlide: 1,
        },
      },
    ],
  };
  return (
    <Container>
      <SlidesBlock>
        <Slider ref={slickRef} {...settings}>
          <Slide slide={data[0]} />
          <Slide slide={data[1]} />
          <Slide slide={data[2]} />
          <Slide slide={data[3]} />
          <Slide slide={data[4]} />
          <Slide slide={data[5]} />
          <Slide slide={data[6]} />
          <Slide slide={data[7]} />
          <Slide slide={data[8]} />
          <Slide slide={data[9]} />
          <Slide slide={data[10]} />
        </Slider>
      </SlidesBlock>
      <PrevButton onClick={previous}>
        <img src={leftBtnActive} alt='prev' />
      </PrevButton>
      <NextButton onClick={next}>
        <img src={rightBtnActive} alt='next' />
      </NextButton>
    </Container>
  );
};
export default Slides;

const Container = styled.div`
  display: flex;
  margin: 14rem auto 0 3rem;
  justify-content: center;
  align-items: center;
`;

const SlidesBlock = styled.div`
  width: 84%;
  overflow: hidden;
  height: 76rem;
  .slick-dots {
    .slick-active {
      button::before {
        margin-top: 5rem;
        color: ${palette.orange};
        font-size: 10px;
        line-height: 10px;
      }
    }
    button::before {
      color: ${palette.Darkgray};
      margin-top: 5rem;
      font-size: 10px;
      line-height: 10px;
    }
  }
`;

const defaultButtonStyle = css`
  position: absolute;
  border: none;
  border-radius: 50%;
  background: none;
  outline: none;
  cursor: pointer;
`;

const PrevButton = styled.button`
  ${defaultButtonStyle};
  left: 3rem;
  width: 7.3%;
`;

const NextButton = styled.button`
  ${defaultButtonStyle};
  right: 3rem;
  width: 7.3%;
`;
