const CircleData = [
  {
    idx: 'O',
    meaning: '[ Objective ]',
    text: `객관적인 분석을 통한\n투자 심의 과정`,
  },
  {
    idx: 'N',
    meaning: '[ Novel ]',
    text: '미래성장동력이 풍부한 분야에\n새롭고 신선한 기회를 찾아 투자',
  },
  {
    idx: 'E',
    meaning: '[ Empirical ]',
    text: `경험주의에 입각한\n철저한 투자 프로세스`,
  },
];

export default CircleData;
