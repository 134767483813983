import React from 'react';
import styled from 'styled-components';
import palette from '../styles/palette';
import FooterLogo from '../assets/images/logo_footer.png';
import { useMedia } from 'react-media';
const Footer = () => {
  const isMobile = useMedia({
    query: '(max-width:428px)',
  });
  return (
    <Container>
      <Background>
        <Logo src={FooterLogo} />
        <Content>
          <Title>캐피탈원 주식회사</Title>
          {isMobile ? (
            <Sub>
              회사명 : 캐피탈원 주식회사 | 대표이사 : 유형권
              <br />
              사업자등록번호 : 214-88-44380 | 대표번호 : +82 2 595 7450
              <br />
              주소 : 서울특별시 영등포구 국회대로70길 18 한양빌딩 405호
            </Sub>
          ) : (
            <>
              <Sub>회사명 : 캐피탈원 주식회사 | 대표이사 : 유형권 | 사업자등록번호 : 214-88-44380</Sub>
              <Sub>
                대표번호 : +82 2 595 7450 | 주소 : (주) 캐피탈원 : 서울특별시 영등포구 국회대로70길 18 한양빌딩 405호
              </Sub>
            </>
          )}
        </Content>
      </Background>
      <OrangeBar>
        {isMobile ? (
          <span>
            Copyright ⓒ 2022 CapitalOne Co., Ltd.
            <br /> All rights are reserved.
          </span>
        ) : (
          <span>Copyright ⓒ 2022 CapitalOne Co., Ltd. All rights are reserved.</span>
        )}
      </OrangeBar>
    </Container>
  );
};

export default Footer;

const Container = styled.div`
  font-size: 0px;
  @media (max-width: 428px) {
  }
`;

const Background = styled.div`
  display: inline-flex;
  width: 100%;
  height: 33.1rem;
  background-color: ${palette.Black};
  @media (max-width: 428px) {
    width: 100%;
    height: 32rem;
    display: flex;
    flex-direction: column;
  }
`;

const Logo = styled.img`
  margin-top: 7.49rem;
  width: 27.6rem;
  height: 11.86rem;
  margin-left: 21rem;
  @media (max-width: 428px) {
    margin-top: 8.16rem;
    width: 16.8rem;
    height: 7.22rem;
    margin-left: 2rem;
  }
`;

const Content = styled.div`
  text-align: left;
  margin-left: 18.3rem;
  height: 100%;
  @media (max-width: 428px) {
    margin-left: 2rem;
  }
`;

const Title = styled.div`
  font-size: 2.8rem;
  font-weight: 500;
  margin-top: 11.68rem;
  color: white;
  padding-bottom: 1.8rem;
  @media (max-width: 428px) {
    margin-top: 3.5rem;
    font-size: 1.8rem;
    padding-bottom: 0;
  }
`;

const Sub = styled.div`
  font-family: 'Spoqa Han Sans Neo';
  font-size: 2rem;
  color: ${palette.Darkgray};
  margin-top: 1.5rem;
  font-weight: 300;
  @media (max-width: 428px) {
    font-size: 1.2rem;
    margin-top: 1.2rem;
  }
`;

const OrangeBar = styled.div`
  display: flex;
  font-family: AppleSDGothicNeo;
  align-items: center;
  width: 100%;
  height: 4.2rem;
  background-color: ${palette.orange};
  font-size: 2rem;
  color: white;
  & > span {
    margin-left: 21rem;
  }
  @media (max-width: 428px) {
    font-size: 1.2rem;
    text-align: left;
    & > span {
      margin-left: 2rem;
    }
  }
`;
