const SlideData = [
  {
    year: 2021,
    contents: [
      {
        title: `캐피탈원 쇼박스-iMBC콘텐츠 \n투자조합 결성`,
        subtitle: '(약정총액 50.2억원)',
      },
      {
        title: '대표이사 변경',
        subtitle: '(유형권 취임)',
      },
    ],
  },
  {
    year: 2019,
    contents: [
      {
        title: ` 캐피탈원 해양신산업 \n투자조합 결성 `,
        subtitle: '(약정총액 145억원)',
      },
      {
        title: '대표이사 변경',
        subtitle: '(송승엽 취임)',
      },
    ],
  },
  {
    year: 2018,
    contents: [
      {
        title: '캐피탈원 조선업구조개선 \n투자조합 결성',
        subtitle: '(약정총액 200억원)',
      },
      {
        title: '캐피탈원 중저예산영화전문 \n투자조합 청산',
        subtitle: '',
      },
      {
        title: '캐피탈원 한국영화르네상스 \n투자조합 청산',
        subtitle: '',
      },
      {
        title: '캐피탈원 콘텐츠가치평가 \n투자조합 결성',
        subtitle: '(약정총액 100.1억원)',
      },
    ],
  },
  {
    year: 2017,
    contents: [
      {
        title: '자본금 증자',
        subtitle: '(납입자본금: 58.5억원)',
      },
      {
        title: '캐피탈원 중저예산영화전문 \n투자조합2호 결성',
        subtitle: '(약정총액 240억원)',
      },
      {
        title: 'AUM 1,200억원 달성',
        subtitle: '',
      },
      {
        title: '캐피탈원 다양성영화전문 \n투자조합 청산',
        subtitle: '',
      },
    ],
  },
  {
    year: 2016,
    contents: [
      {
        title: '캐피탈원 여성창조기업 \n투자조합 결성',
        subtitle: '(약정총액 145억원)',
      },
      {
        title: '캐피탈원 롯데-iMBC콘텐츠 \n투자조합 결성',
        subtitle: '(약정총액 60.2억원)',
      },
    ],
  },
  {
    year: 2015,
    contents: [
      {
        title: '캐피탈원 농림수산식품 \n투자조합3호 결성',
        subtitle: '(약정총액 150억원)',
      },
    ],
  },
  {
    year: 2014,
    contents: [
      {
        title: '캐피탈원 농림수산식품 \n투자조합2호 결성',
        subtitle: '(결성총액 150억원)',
      },
      {
        title: '중소기업청 주관 창투사평가 \n종합등급 “A” 획득',
        subtitle: '',
      },
    ],
  },
  {
    year: 2012,
    contents: [
      {
        title: '캐피탈원 한국영화르네상스 \n투자조합 결성',
        subtitle: '(결성총액 100억원)',
      },
    ],
  },
  {
    year: 2011,
    contents: [
      {
        title: '캐피탈원 중저예산영화전문 \n투자조합 결성',
        subtitle: '(결성총액 120억원)',
      },
      {
        title: '캐피탈원 농림수산식품 \n투자조합 결성',
        subtitle: '(결성총액 200억원)',
      },
    ],
  },
  {
    year: 2010,
    contents: [
      {
        title: '캐피탈원 다양성영화전문 \n투자조합 결성',
        subtitle: '(결성총액 50억원)',
      },
    ],
  },
  {
    year: 2009,
    contents: [
      {
        title: '법인 설립 ',
        subtitle: '(대표이사: 여한구 / \n납입자본금: 50억원)',
      },
      {
        title: '창업투자회사 등록',
        subtitle: '(중소기업청)',
      },
    ],
  },
];

export default SlideData;
