import React from 'react';
import styled from 'styled-components';
import Logo from '../assets/images/Logo.svg';
import palette from '../styles/palette';
import OffCanvas from '../components/Hambug';
import { useMedia } from 'react-media';
const Home = () => {
  const isMobile = useMedia({
    query: '(max-width:480px)',
  });
  return (
    <Background>
      <Container>
        <LogoBox src={Logo} alt='logo' />
        <OffCanvas />
        <ContentBox>
          IN SOMNIS
          <span className='orange'> VERITAS</span>
        </ContentBox>
        <Footer>
          {isMobile ? (
            <>
              캐피탈원 주식회사 | Address. 18,Gukhoe-daero 70-gil, Yeongdeungpo-gu, Seoul, Korea
              <br /> | Tel. +82-2-595-7450 | Fax. +82-2-595-7577 | e-mail. admin@capitalonevc.co.kr <br />
              Copyright ⓒ 2022 CapitalOne Co., Ltd. All rights are reserved.
            </>
          ) : (
            <>
              캐피탈원 주식회사 | Address. 18,Gukhoe-daero 70-gil, Yeongdeungpo-gu, Seoul, Korea | Tel. +82-2-595-7450 |
              Fax. +82-2-595-7577 | e-mail. admin@capitalonevc.co.kr <br />
              Copyright ⓒ 2022 CapitalOne Co., Ltd. All rights are reserved.
            </>
          )}
        </Footer>
      </Container>
    </Background>
  );
};

export default Home;

const Background = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  background: url(https://user-images.githubusercontent.com/55784772/155583211-ab1ab2b6-5da4-4ef6-b14a-77f5a0a0e469.png);
  background-size: cover;
  padding-bottom: 3.6rem;
  @media (max-width: 480px) {
    height: 100vh;
    background-position: center;
    background-repeat: no-repeat;
  }
`;

const LogoBox = styled.img`
  margin: 4.4rem 5.7rem;
  width: 17.1rem;
  height: 4.9rem;
  @media (max-width: 480px) {
    width: 11.2rem;
    height: 3.2rem;
    margin: 0;
  }
`;

const Container = styled.div`
  @media (max-width: 480px) {
    width: 95%;
    margin: 2rem auto;
  }
`;

const ContentBox = styled.div`
  margin-top: 20%;
  font-size: 12rem;
  text-align: center;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 300;
  color: #fff;
  .orange {
    color: ${palette.orange};
  }
  @media (max-width: 480px) {
    margin-top: 26.8rem;
    margin-bottom: 0;
    font-size: 4.4rem;
  }
`;
const Footer = styled.div`
  color: ${palette.Darkgray};
  width: 100%;
  text-align: center;
  font-family: 'Spoqa Han Sans Neo', 'sans-serif';
  font-style: normal;
  position: absolute;
  bottom: 3.6rem;
  font-size: 1.6rem;
  @media (max-width: 480px) {
    font-size: 0.8rem;
    bottom: 2rem;
  }
`;
