const EquityCardData = [
  [
    {
      id: 0,
      img: 'https://user-images.githubusercontent.com/55784772/199648695-668ec9a3-fab4-41cd-82ed-5476be9c751a.png',
      cardTitle: '네이처글루텍',
      subtitle: '메디컬 생체 접착제 개발',
      url: 'http://natureglue.com/',
    },
    {
      id: 1,
      img: 'https://user-images.githubusercontent.com/55784772/172584037-fbc3c558-2c31-4a44-83d2-ad59fd031c8d.png',
      cardTitle: '리스큐어바이오사이언시스',
      subtitle: '마이크로바이옴 치료제 개발',
      url: 'http://www.liscure.bio',
    },
    {
      id: 2,
      img: 'https://user-images.githubusercontent.com/55784772/172584137-e39df657-9424-46fe-86ad-46f7abf2c8c5.png',
      cardTitle: '칸워크홀딩',
      subtitle: '전기구동 CNC Machine',
      url: 'http://www.khanchuck.com',
    },
    {
      id: 3,
      img: 'https://user-images.githubusercontent.com/55784772/158031478-1045b131-1275-42f0-8543-52e2ac622b2f.png',
      cardTitle: '비브스튜디오스',
      subtitle: 'VR, AR, VFX, CGI 솔루션',
      url: 'www.vivestudios.com',
    },

    {
      id: 4,
      img: 'https://user-images.githubusercontent.com/55784772/158031493-6f185816-d398-44aa-a5a5-904145106001.png',
      cardTitle: '유니드캐릭터',
      subtitle: '애니메이션 콘텐츠 제작',
      url: 'www.youneedcharacter.com',
    },

    {
      id: 5,
      img: 'https://user-images.githubusercontent.com/55784772/158031496-88ed9e05-729c-40ff-a9dd-09cb9738823f.png',
      cardTitle: '와이낫미디어',
      subtitle: '웹드라마 웹예능 제작',
      url: 'whynot.video',
    },
    {
      id: 6,
      img: 'https://user-images.githubusercontent.com/55784772/158031502-27f84581-c3f5-4c9c-9921-eff54672b570.png',
      cardTitle: '빈센',
      subtitle: '전기, 수소 선박 제조',
      url: 'www.vinssen.com',
    },
    {
      id: 7,
      img: 'https://user-images.githubusercontent.com/55784772/158031504-82577e7f-0fab-4e51-a453-db4aaac389c1.png',
      cardTitle: '캐리소프트',
      subtitle: '아동용 콘텐츠 제작',
      url: 'www.carriesoft.com',
    },
    {
      id: 8,
      img: 'https://user-images.githubusercontent.com/55784772/158031514-37c812ff-f34d-414b-a24d-25aefac8b784.png',
      cardTitle: '타스글로벌',
      subtitle: '수중 선박 청소 로봇',
      url: 'http://usmtas.kr/',
    },
    {
      id: 9,
      img: 'https://user-images.githubusercontent.com/55784772/158154215-3bd5c7a5-188e-4a4f-a7e9-e3199b3a478d.png',
      cardTitle: '에스에이엠지엔터테인먼트',
      subtitle: '3D, AR 콘텐츠 제작',
      url: 'samg.net',
    },
    {
      id: 10,
      img: 'https://user-images.githubusercontent.com/55784772/158031520-8e698d1b-02cf-4f8d-83f6-b0c097da4385.png',
      cardTitle: '인벤티지랩',
      subtitle: '신약 개발 솔루션',
      url: 'www.inventagelab.com',
    },
    {
      id: 11,
      img: 'https://user-images.githubusercontent.com/55784772/158031521-7b39fbb9-51a1-46b8-a9c9-a55b5eb0fb31.png',
      cardTitle: '칼로스메디컬',
      subtitle: '의료기기',
      url: '',
    },
    {
      id: 12,
      img: 'https://user-images.githubusercontent.com/55784772/158031532-3df0717e-3d43-4078-8382-e14b7fd5c878.png',
      cardTitle: '째깍악어',
      subtitle: '육아 솔루션 플랫폼',
      url: 'www.tictoccroc.com',
    },
    {
      id: 13,
      img: 'https://user-images.githubusercontent.com/55784772/158031535-9c177dc6-f0a4-4dd9-9395-f445883d5802.png',
      cardTitle: '지바이오로직스',
      subtitle: '바이오 의약품 개발 플랫폼',
      url: 'www.gbiologics.com',
    },
    {
      id: 14,
      img: 'https://user-images.githubusercontent.com/55784772/158031537-c11ace3e-3984-4e1b-9564-ebca710c8641.png',
      cardTitle: '생활연구소',
      subtitle: '홈 서비스 플랫폼',
      url: 'www.cleaninglab.co.kr',
    },
    {
      id: 15,
      img: 'https://user-images.githubusercontent.com/55784772/158031542-33309613-2cf0-43c4-ae2f-b78db9c3dd2d.png',
      cardTitle: '트레드링스',
      subtitle: '수출입 물류 플랫폼',
      url: 'www.tradlinx.com',
    },
  ],
  [
    {
      id: 16,
      img: 'https://user-images.githubusercontent.com/55784772/158031547-f2539e32-de6b-4de8-b6eb-96722c89c1df.png',
      cardTitle: '룰루랩',
      subtitle: 'AI 뷰티 솔루션',
      url: 'lulu-lab.com',
    },
    {
      id: 17,
      img: 'https://user-images.githubusercontent.com/55784772/158031551-b1becc82-35ec-412c-8a91-30c300e84c4f.png',
      cardTitle: '데스틴파워',
      subtitle: 'ESS 솔루션',
      url: 'www.destin.co.kr',
    },
    {
      id: 18,
      img: 'https://user-images.githubusercontent.com/55784772/158031555-105a8048-228e-48f8-adb2-ce5990a96987.png',
      cardTitle: '인셀',
      subtitle: '종합 배터리 솔루션',
      url: 'www.incell.kr',
    },
    {
      id: 19,
      img: 'https://user-images.githubusercontent.com/55784772/158031559-33839a29-b374-49c2-aadd-ff6fd58fe53f.png',
      cardTitle: '삼건세기',
      subtitle: 'BWTS 및 조선기자재',
      url: 'www.samkunok.com',
    },
    {
      id: 20,
      img: 'https://user-images.githubusercontent.com/55784772/158031570-75241c1e-1e43-441a-aa43-6075e5bc6805.png',
      cardTitle: '페이레터',
      subtitle: '온라인 전자결제 솔루션',
      url: 'www.payletter.com',
    },
    {
      id: 21,
      img: 'https://user-images.githubusercontent.com/55784772/158031577-c70818d2-d2b8-4485-a24f-69fd45384682.png',
      cardTitle: '마마쿡',
      subtitle: 'F&B',
      url: 'mamacook.co.kr',
    },
    {
      id: 22,
      img: 'https://user-images.githubusercontent.com/55784772/158031583-a55b21bc-e135-4f55-9003-51858fffca2c.png',
      cardTitle: '코웰',
      subtitle: '특수 소재 제조',
      url: 'www.kowel.co.kr',
    },
    {
      id: 23,
      img: 'https://user-images.githubusercontent.com/55784772/158031588-5943b78c-3d63-45d3-95f7-b12f678e8133.png',
      cardTitle: '화인베스틸',
      subtitle: '철강 제품 제조',
      url: 'www.finebesteel.com',
    },
    {
      id: 24,
      img: 'https://user-images.githubusercontent.com/55784772/158031592-bfbbfabe-a2f6-4b6f-aad7-95def25ed1d4.png',
      cardTitle: '삼정터빈',
      subtitle: '터빈 제조',
      url: 'www.turbine.co.kr',
    },
    {
      id: 25,
      img: 'https://user-images.githubusercontent.com/55784772/158031596-fbeb4169-348c-459b-b42e-610f17c83b6e.png',
      cardTitle: '엔피코어',
      subtitle: 'AI 보안 솔루션',
      url: 'www.npcore.com',
    },
    {
      id: 26,
      img: 'https://user-images.githubusercontent.com/55784772/158031603-7fad2c79-7490-4cb0-875d-659b37c68732.png',
      cardTitle: '제이디솔루션',
      subtitle: '사운드 솔루션',
      url: 'www.jdsol.co.kr',
    },
    {
      id: 27,
      img: 'https://user-images.githubusercontent.com/55784772/158031611-16ef94d7-9991-4967-b072-df44a33a44ca.png',
      cardTitle: '한백식품',
      subtitle: '수산물 가공 도소매',
      url: 'hanbaekfood.com',
    },
    {
      id: 28,
      img: 'https://user-images.githubusercontent.com/55784772/158031621-961358be-1eb7-4363-9607-ed7ee95aa605.png',
      cardTitle: '한국교육시스템',
      subtitle: '유아 용품 솔루션',
      url: 'coreshop.kr',
    },
  ],
];

export default EquityCardData;
