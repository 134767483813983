import React from 'react';
import styled from 'styled-components';
import palette from '../styles/palette';
import Slides from '../components/Slides/Slides';
import MobileSlides from '../components/Slides/MobileSlides';
import { useMedia } from 'react-media';
const History = () => {
  const isMobile = useMedia({
    query: '(min-width: 320px) and (max-width:428px)',
  });
  return (
    <a name='history'>
      <Background>
        <Header>History</Header>
        <Container>{isMobile ? <MobileSlides /> : <Slides />}</Container>
      </Background>
    </a>
  );
};

export default History;

const Background = styled.div`
  background: #f7f7f7;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding-bottom: 11.7rem;
  @media (max-width: 428px) {
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
    padding-bottom: 12rem;
  }
`;
const Container = styled.div`
  align-items: center;
`;
const Header = styled.div`
  margin: 5.7rem 0 0 5.7rem;
  text-align: left;
  font-family: 'Inter';
  font-weight: 300;
  color: ${palette.Black};
  font-size: 2.8rem;
  @media (max-width: 428px) {
    width: 10rem;
    font-size: 1.8rem;
    margin: 2.6rem 0 0 2rem;
  }
`;
