import React from 'react';
import styled from 'styled-components';

const PosterItem = ({ poster }) => {
  return (
    <>
      <PosterBox>
        <Image src={poster.poster} alt='poster' />
        <Hover className='card-hover'>
          <Movie>영화</Movie>
          <Title>{poster.title}</Title>
          <Opening>{poster.openingDate}</Opening>
          <Company>{poster.company}</Company>
        </Hover>
      </PosterBox>
    </>
  );
};
const PosterBox = styled.div`
  position: relative;
  cursor: pointer;
  :hover {
    & > .card-hover {
      opacity: 1;
    }
  }
  @media (max-width: 480px) {
    font-size: 1rem;
  }
`;
const Movie = styled.div`
  margin-top: 12.7rem;
  font-size: 2.2rem;
  font-weight: 100;
  font-family: 'Spoqa Han Sans Neo';
  @media (max-width: 480px) {
    margin-top: 4.8rem;
    font-size: 1.2rem;
  }
`;
const Title = styled.div`
  margin-top: 0.5rem;
  font-size: 3.4rem;
  font-family: 'Spoqa Han Sans Neo';
  font-weight: 500;
  @media (max-width: 480px) {
    margin-top: 0.4rem;
    font-size: 2rem;
  }
`;
const Opening = styled.div`
  margin-top: 5.8rem;
  font-family: 'Spoqa Han Sans Neo';
  font-weight: 300;
  font-size: 1.8rem;
  @media (max-width: 480px) {
    font-size: 1rem;
  }
`;
const Company = styled.div`
  font-size: 1.8rem;
  font-family: 'Spoqa Han Sans Neo';
  font-weight: 300;
  @media (max-width: 480px) {
    font-size: 1rem;
  }
`;
const Image = styled.img`
  width: 28.2rem;
  height: 39.2rem;
  border-radius: 1rem;
  @media (max-width: 480px) {
    width: 15.2rem;
    height: 21.2rem;
    border-radius: 0.2rem;
  }
`;
const Hover = styled.div`
  position: absolute;
  font-family: 'Spoqa Han Sans Neo';
  opacity: 0;
  background-color: rgba(243, 111, 32, 0.85);
  top: 0;
  left: 0;
  transition: all 0.5s;
  border-radius: 1rem;
  width: 28.4rem;
  height: 39.4rem;
  text-align: center;
  color: white;
  @media (max-width: 480px) {
    width: 15.2rem;
    height: 21.2rem;
    border-radius: 0.2rem;
  }
`;
export default PosterItem;
