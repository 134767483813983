import React, { useState } from 'react';
import Hamburger from '../assets/images/Hamburger.svg';
import CloseButton from '../assets/images/CloseButton.svg';
import styled from 'styled-components';
import { Offcanvas } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import aboutus from '../assets/offcanvas/aboutus_btn.png';
import history from '../assets/offcanvas/history_btn.png';
import portfolio from '../assets/offcanvas/portfolio_btn.png';
import contact from '../assets/offcanvas/contact_btn.png';
import about_act from '../assets/offcanvas/aboutus_btn_activated.png';
import history_act from '../assets/offcanvas/history_btn_activated.png';
import portfolio_act from '../assets/offcanvas/portfolio_btn_activated.png';
import contact_act from '../assets/offcanvas/contact_btn_activated.png';
import { useMedia } from 'react-media';

const Hambug = () => {
  const isMobile = useMedia({ query: '(max-width:480px)' });
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setShow(false);
  };
  const toggleShow = () => setShow((s) => !s);
  const [aButton, setAbutton] = useState(aboutus);
  const [hButton, setHbutton] = useState(history);
  const [pButton, setPbutton] = useState(portfolio);
  const [cButton, setCbutton] = useState(contact);
  const HoverABtn = () => {
    setAbutton(about_act);
  };
  const HoverHBtn = () => {
    setHbutton(history_act);
  };
  const HoverPBtn = () => {
    setPbutton(portfolio_act);
  };
  const HoverCBtn = () => {
    setCbutton(contact_act);
  };

  const unHoveredBtn = () => {
    setAbutton(aboutus);
    setHbutton(history);
    setPbutton(portfolio);
    setCbutton(contact);
  };
  return (
    <Container>
      <Button src={Hamburger} alt='offcanvas hambug' onClick={toggleShow} />
      <Canvas>
        <Offcanvas
          style={
            isMobile
              ? { backdropFilter: 'blur(8.8px)', backgroundColor: 'var(--black)', width: '100%', height: '100%' }
              : { backdropFilter: 'blur(8.8px)', backgroundColor: 'var(--black)', width: '66.9rem', height: '100%' }
          }
          show={show}
          placement='end'
          backdrop='false'
          scroll='true'
          onHide={handleClose}
        >
          <Offcanvas.Header>
            <Button src={CloseButton} alt='close' onClick={handleClose} />
          </Offcanvas.Header>
          <Content>
            <a href='#aboutus'>
              <NavButton
                src={aButton}
                onClick={handleClose}
                onTouchStart={HoverABtn}
                onTouchEnd={unHoveredBtn}
                onMouseEnter={HoverABtn}
                onMouseLeave={unHoveredBtn}
                alt='aboutus'
              />
            </a>
            <a href='#history'>
              <NavButton
                src={hButton}
                onClick={handleClose}
                onTouchStart={HoverHBtn}
                onTouchEnd={unHoveredBtn}
                onMouseEnter={HoverHBtn}
                onMouseLeave={unHoveredBtn}
                alt='history'
              />
            </a>
            <a href='#portfolio'>
              <NavButton
                src={pButton}
                onClick={handleClose}
                onTouchStart={HoverPBtn}
                onTouchEnd={unHoveredBtn}
                onMouseEnter={HoverPBtn}
                onMouseLeave={unHoveredBtn}
                alt='portfolio'
              />
            </a>
            <a href='#contact'>
              <NavButton
                src={cButton}
                onClick={handleClose}
                onTouchStart={HoverCBtn}
                onTouchEnd={unHoveredBtn}
                onMouseEnter={HoverCBtn}
                onMouseLeave={unHoveredBtn}
                alt='contact'
              />
            </a>
          </Content>
        </Offcanvas>
      </Canvas>
    </Container>
  );
};
const Canvas = styled.div`
  width: 66.9rem;
  height: 100%;
`;
const Button = styled.img`
  position: fixed;
  right: 4rem;
  top: 3rem;
  @media (min-width: 320px) and (max-width: 428px) {
    top: 1.4rem;
    right: 1.4rem;
    width: 4.4rem;
    height: 4.4rem;
  }
`;
const Container = styled.div``;
const NavButton = styled.img`
  width: 39.9rem;
  height: 8.2rem;
  margin-bottom: 4rem;
  cursor: pointer;
  @media (min-width: 320px) and (max-width: 428px) {
    width: 20rem;
    height: 4.2rem;
    margin: 0 0 2.8rem 1.2rem;
  }
`;
const Content = styled.div`
  margin: 48.4rem 0.3rem 10.8rem 0;
  display: flex;
  flex-direction: column;
  @media (min-width: 320px) and (max-width: 428px) {
    margin: 32rem 0 6.4rem 0;
  }
`;

export default Hambug;
