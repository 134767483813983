import React, { useRef } from 'react';
import styled from 'styled-components';
import Slide from './SlideItem';
import slideData from './SlideData';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import palette from '../../styles/palette';

const Slides = () => {
  const data = slideData;

  const slickRef = useRef(null);

  const settings = {
    dots: true,
    speed: 500,
    arrows: false,
    className: 'center',
    centerMode: true,
    infinite: true,
    centerPadding: '60px',
    slidesToShow: 1,
    speed: 500,
  };
  return (
    <Container>
      <SlidesBlock>
        <Slider ref={slickRef} {...settings}>
          <Slide slide={data[0]} />
          <Slide slide={data[1]} />
          <Slide slide={data[2]} />
          <Slide slide={data[3]} />
          <Slide slide={data[4]} />
          <Slide slide={data[5]} />
          <Slide slide={data[6]} />
          <Slide slide={data[7]} />
          <Slide slide={data[8]} />
          <Slide slide={data[9]} />
          <Slide slide={data[10]} />
        </Slider>
      </SlidesBlock>
    </Container>
  );
};
export default Slides;

const Container = styled.div`
  display: flex;
  margin: 12.2rem auto 0 auto;
  justify-content: center;
  align-items: center;
  @media (max-width: 480px) {
    margin: 6rem auto 0 auto;
  }
`;

const SlidesBlock = styled.div`
  width: 163.8rem;
  overflow: hidden;
  height: 74.1rem;
  .slick-dots {
    .slick-active {
      button::before {
        margin-top: 5rem;
        color: ${palette.orange};
        font-size: 20px;
        line-height: 20px;
      }
    }
    button::before {
      color: ${palette.Darkgray};
      margin-top: 5rem;
      font-size: 20px;
      line-height: 20px;
    }
  }
  @media (max-width: 480px) {
    width: 100%;
    height: 42rem;
    overflow: visible;
    .slick-dots {
      .slick-active {
        button::before {
          margin-top: 5rem;
          color: ${palette.orange};
          font-size: 10px;
          line-height: 10px;
        }
      }
      button::before {
        color: ${palette.Darkgray};
        margin-top: 5rem;
        font-size: 10px;
        line-height: 10px;
      }
    }
  }
`;
