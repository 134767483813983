import React from 'react';
import styled from 'styled-components';
import palette from '../../styles/palette';

const SlideItem = ({ slide }) => {
  return (
    <SlideBox>
      <ContentBox>
        <Year>{slide.year}</Year>
        <Title>{slide.contents[0].title}</Title>
        <SubTitle>{slide.contents[0].subtitle}</SubTitle>
        <Title>{slide.contents[1]?.title}</Title>
        <SubTitle>{slide.contents[1]?.subtitle}</SubTitle>
        <Title>{slide.contents[2]?.title}</Title>
        <SubTitle>{slide.contents[2]?.subtitle}</SubTitle>
        <Title>{slide.contents[3]?.title}</Title>
        <SubTitle>{slide.contents[3]?.subtitle}</SubTitle>
      </ContentBox>
    </SlideBox>
  );
};

export default SlideItem;

const SlideBox = styled.div`
  width: 92%;
  height: 66.1rem;
  display: flex;
  background: white;
  @media (min-width: 320px) and (max-width: 428px) {
    width: 93%;
    height: 39rem;
  }
`;

const ContentBox = styled.div`
  width: 92%;
  height: 100%;
  margin: 0 auto;
  @media (min-width: 320px) and (max-width: 428px) {
    width: 92%;
  }
`;
const Year = styled.div`
  margin: 2.4rem 0rem 3rem;
  font-family: 'Inter';
  font-weight: 300;
  color: ${palette.orange};
  font-size: 4.8rem;
  left: 0;
  @media (min-width: 320px) and (max-width: 428px) {
    font-size: 3.2rem;
    margin: 2rem 0rem 1.2rem;
  }
`;
const Title = styled.div`
  left: 0;
  white-space: pre-wrap;
  margin-top: 2.3rem;
  font-family: 'Spoqa Han Sans Neo', 'sans-serif';
  font-style: normal;
  font-weight: 500;
  color: ${palette.Black};
  font-size: 2.8rem;
  left: 0;
  @media (min-width: 320px) and (max-width: 428px) {
    font-size: 1.7rem;
    margin-top: 1.2rem;
  }
`;
const SubTitle = styled.div`
  margin-top: 0rem;
  white-space: pre-wrap;
  font-family: 'Spoqa Han Sans Neo', 'sans-serif';
  font-style: normal;
  font-weight: 300;
  color: ${palette.Darkgray};
  font-size: 2.4rem;
  @media (min-width: 320px) and (max-width: 428px) {
    font-size: 1.3rem;
  }
`;
