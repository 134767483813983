import React from 'react';
import styled from 'styled-components';
import palette from '../../styles/palette';

const CircleItem = ({ circle }) => {
  return (
    <>
      <CircleBox>
        <Index>{circle.idx}</Index>
        <TextBox>
          <Sub>{circle.meaning}</Sub>
          <Text>{circle.text}</Text>
        </TextBox>
      </CircleBox>
    </>
  );
};

const CircleBox = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 12.1rem;
`;
const Index = styled.div`
  display: flex;
  color: ${palette.orange};
  font-family: 'Inter';
  width: 9rem;
  font-size: 10rem;
  justify-content: center;
  align-items: center;
`;
const TextBox = styled.div`
  flex-direction: column;
  margin: auto 1.8rem;
  width: 18rem;
`;
const Sub = styled.div`
  color: ${palette.Darkgray};
  font-size: 1.8rem;
  margin-top: 0;
  font-family: 'Inter';
  font-weight: 100;
`;

const Text = styled.div`
  font-size: 1.25rem;
  white-space: pre-wrap;
  font-family: 'Spoqa Han Sans Neo';
  font-weight: 500;
`;
export default CircleItem;
