const PosterData = [
  [
    {
      id: 0,
      title: '모가디슈',
      poster: 'https://user-images.githubusercontent.com/55784772/159882032-e82082ce-24ae-44a7-8740-5f053c94a783.png',
      openingDate: '개봉일 : 2021.07.28.',
      company: ' 배급사 : 롯데엔터테인먼트',
    },
    {
      id: 1,
      title: '싱크홀',
      poster: 'https://user-images.githubusercontent.com/55784772/159882135-70b5da56-3a91-49a2-993b-4d8a61eaef2d.png',
      openingDate: '개봉일 : 2020.08.11.',
      company: ' 배급사 : 쇼박스',
    },
    {
      id: 2,
      title: '신세계',
      poster: 'https://user-images.githubusercontent.com/55784772/159882156-44e7a603-89ea-41bf-8808-f525cb829916.png',
      openingDate: '개봉일 : 2013.02.21.',
      company: '배급사 : 넥스트엔터테인먼트',
    },
    {
      id: 3,
      title: '건축학개론',
      poster: 'https://user-images.githubusercontent.com/55784772/159882176-4ce8055b-3dc3-439b-8f8a-de20ce004f5d.png',
      openingDate: '개봉일 : 2012.03.22.',
      company: '배급사 : 롯데엔터테인먼트',
    },
    {
      id: 4,
      title: '7번방의 선물',
      poster: 'https://user-images.githubusercontent.com/55784772/159882196-bf817479-a938-4550-bd5b-e5fbc659a4b2.png',
      openingDate: '개봉일 : 2013.01.23.',
      company: '배급사 : 넥스트엔터테인먼트',
    },
    {
      id: 5,
      title: '변호인',
      poster: 'https://user-images.githubusercontent.com/55784772/159882212-1bdf2d27-b932-492b-ac19-1fb63348da71.png',
      openingDate: '개봉일 : 2013.12.18.',
      company: '배급사 : 넥스트엔터테인먼트',
    },
    {
      id: 6,
      title: '숨바꼭질',
      poster: 'https://user-images.githubusercontent.com/55784772/159882234-adb01f6c-ca23-426a-bd91-1a81a6ad777f.png',
      openingDate: '개봉일 : 2013.08.14.',
      company: '배급사 : 넥스트엔터테인먼트',
    },
    {
      id: 7,
      title: '검은사제들',
      poster: 'https://user-images.githubusercontent.com/55784772/159882255-93a8d94f-30b6-450f-b0ac-4fa23b68b07b.png',
      openingDate: '개봉일 : 2015.11.05.',
      company: '배급사 : CJ엔터테인먼트',
    },
    {
      id: 8,
      title: '신과함께',
      poster: 'https://user-images.githubusercontent.com/55784772/159882299-e02718ee-57ce-463e-aa1d-4fd2086496b0.png',
      openingDate: '개봉일 : 2017.12.20.',
      company: '배급사 : 롯데엔터테인먼트',
    },
    {
      id: 9,
      title: '완벽한 타인',
      poster: 'https://user-images.githubusercontent.com/55784772/159882328-1b6ac664-bf86-4035-a03b-01b44ba3da69.png',
      openingDate: '개봉일 : 2018.10.31.',
      company: '배급사 : 롯데엔터테인먼트',
    },
    {
      id: 10,
      title: '청년경찰',
      poster: 'https://user-images.githubusercontent.com/55784772/159882448-9d6b3ed6-f0f3-4178-aaad-bf12a718490a.png',
      openingDate: '개봉일 : 2017.08.09.',
      company: '배급사 : 롯데엔터테인먼트',
    },
    {
      id: 11,
      title: '악인전',
      poster: 'https://user-images.githubusercontent.com/55784772/159882496-005990a0-068f-47b2-8e1a-e3d822ffce65.png',
      openingDate: '개봉일 : 2019.05.15.',
      company: '배급사 : 에이스메이커무비웍스',
    },
  ],
  [
    {
      id: 12,
      title: 'Mr.Show',
      poster: 'https://user-images.githubusercontent.com/55784772/159882526-47912ae1-e000-4a52-99d7-55693b09a1f9.png',
      openingDate: '개봉일 : 2014.03.27.',
      company: '',
    },
    {
      id: 13,
      title: '연애의 온도',
      poster: 'https://user-images.githubusercontent.com/55784772/159882549-481fde34-3761-4f79-901b-e45143ec22d5.png',
      openingDate: '개봉일 : 2013.03.21.',
      company: '배급사 : 롯데엔터테인먼트',
    },
    {
      id: 14,
      title: '스물',
      poster: 'https://user-images.githubusercontent.com/55784772/159882569-46cb78c3-5564-4a53-b49c-ab03521cf5a5.png',
      openingDate: '개봉일 : 2015.03.25.',
      company: '배급사 : 넥스트엔터테인먼트',
    },
    {
      id: 15,
      title: '정직한 후보',
      poster: 'https://user-images.githubusercontent.com/55784772/159882596-0fde9c0d-ff17-46e2-8033-0014b6a7645d.png',
      openingDate: '개봉일 : 2020.02.12.',
      company: '배급사 : 넥스트엔터테인먼트',
    },
    {
      id: 16,
      title: '해적:도깨비 깃발',
      poster: 'https://user-images.githubusercontent.com/55784772/159882624-1db3bce8-7b05-47eb-95d8-9aea3e3faf4a.png',
      openingDate: '개봉일 : 2022.01.26.',
      company: '배급사 : 롯데엔터테인먼트',
    },
    {
      id: 17,
      title: '비상선언',
      poster: 'https://user-images.githubusercontent.com/55784772/159882638-19d6a410-5bf1-432b-8758-6bd4fbfb1496.png',
      openingDate: '개봉일 : 2022(예정)',
      company: '배급사 : 쇼박스',
    },
  ],
];

export default PosterData;
