import React from 'react';
import styled from 'styled-components';
import palette from '../../styles/palette';
import Line from '../../assets/images/line.svg';
const EquityCardItem = ({ card }) => {
  return (
    <LinkTo href={card.url === '' ? '#portfolio' : `http://${card.url}`}>
      <CardBox>
        <Image src={card.img} alt='' />
        <LineWrapper src={Line} alt='line' />
        <TitleBox>{card.cardTitle}</TitleBox>
        <SubBox>{card.subtitle}</SubBox>
        <Hover className='card-hover'>
          <HoverSub>{card.subtitle}</HoverSub>
          {card.cardTitle === '에스에이엠지엔터테인먼트' ? (
            <HoverTitle>
              에스에이엠지
              <br />
              엔터테인먼트
            </HoverTitle>
          ) : (
            <HoverTitle>{card.cardTitle}</HoverTitle>
          )}
          {card.url === 'samg.net' ? <HoverUrll>samg.net</HoverUrll> : <HoverUrl>{card.url}</HoverUrl>}
        </Hover>
      </CardBox>
    </LinkTo>
  );
};
const LineWrapper = styled.img`
  width: 33rem;
  margin: 0 auto;
  @media (max-width: 480px) {
    width: 13.8rem;
    height: 0.1rem;
  }
`;
const LinkTo = styled.a`
  text-decoration: none;
  text-decoration-line: none;
`;
const Hover = styled.div`
  position: absolute;
  opacity: 0;
  background-color: rgba(243, 111, 32, 0.85);
  top: 0;
  left: 0;
  transition: all 0.5s;
  width: 38.6rem;
  height: 27.3rem;
  border-radius: 2rem;
  @media (max-width: 480px) {
    width: 15.2rem;
    height: 12.6rem;
    border-radius: 0.2rem;
  }
`;

const HoverTitle = styled.div`
  font-size: 3.4rem;
  text-align: center;
  font-family: 'Spoqa Han Sans Neo';
  font-weight: 500;
  color: white;
  @media (max-width: 480px) {
    margin-top: 0rem;
    font-size: 1.4rem;
  }
`;
const HoverSub = styled.div`
  font-size: 2.2rem;
  margin-top: 7.9rem;
  font-family: 'Spoqa Han Sans Neo';
  font-weight: 300;
  color: white;
  @media (max-width: 480px) {
    font-size: 1rem;
    margin-top: 3.5rem;
  }
`;
const HoverUrl = styled.div`
  margin-top: 4.7rem;
  font-size: 2.2rem;
  font-family: 'Inter';
  font-weight: 100;
  text-align: center;
  color: white;
  @media (max-width: 480px) {
    font-size: 1rem;
    margin-top: 1.3rem;
  }
`;
const HoverUrll = styled.div`
  font-size: 2.2rem;
  font-family: 'Inter';
  font-weight: 100;
  text-align: center;
  color: white;
  @media (max-width: 480px) {
    font-size: 1rem;
  }
`;

const CardBox = styled.div`
  position: relative;
  flex-direction: column;
  width: 38.6rem;
  height: 27.3rem;
  background-color: white;
  border-radius: 2rem;
  box-shadow: 5px 5px 30px 0 rgba(0, 0, 0, 0.1);
  text-align: center;
  cursor: pointer;
  :hover {
    & > .card-hover {
      opacity: 1;
    }
  }
  @media (max-width: 480px) {
    width: 15.2rem;
    height: 12.6rem;
    border-radius: 0.2rem;
  }
`;

const Image = styled.img`
  position: relative;
  margin-top: 2.2rem;
  width: 100%;
  height: 16rem;
  @media (max-width: 480px) {
    margin-top: 0.5rem;
    height: 6.2rem;
  }
`;

const TitleBox = styled.div`
  position: relative;
  margin-top: 1rem;
  font-family: 'Spoqa Han Sans Neo';
  font-weight: 500;
  font-size: 2.4rem;
  text-align: center;
  height: 10%;
  color: ${palette.Black};
  @media (max-width: 480px) {
    margin-top: 0rem;
    font-size: 1.4rem;
    height: 13%;
    margin: 0 auto;
    width: 80%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
`;

const SubBox = styled.div`
  margin-top: 0;
  position: relative;
  font-family: 'Spoqa Han Sans Neo';
  font-weight: 300;
  font-size: 1.6rem;
  text-align: center;
  color: ${palette.Darkgray};
  @media (max-width: 480px) {
    font-size: 1rem;
  }
`;

export default EquityCardItem;
