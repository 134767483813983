import React from 'react';
import styled from 'styled-components';
import palette from '../styles/palette';
import CircleGroup from '../assets/images/AboutUs/txtgroup.png';
import CircleData from '../components/AboutUs/CircleData';
import CircleItem from '../components/AboutUs/CircleItem';
import { useMedia } from 'react-media';

const AboutUs = () => {
  const isMobile = useMedia({
    query: '(min-width: 320px) and (max-width:428px)',
  });
  const circleA = CircleData[0];
  const circleB = CircleData[1];
  const circleC = CircleData[2];
  return (
    <>
      <a name='aboutus'>
        <Background>
          <Header>About Us</Header>
          {isMobile ? (
            <ContentBox>
              <CircleItem circle={circleA} />
              <CircleItem circle={circleB} />
              <CircleItem circle={circleC} />
            </ContentBox>
          ) : (
            <ImageWrapper>
              <CircleContainer src={CircleGroup} alt='textgroup'></CircleContainer>
            </ImageWrapper>
          )}
          {isMobile ? (
            <ContentGray>
              객관적인 분석을 통하여 미래성장동력이 풍부한 분야를
              <br /> 새롭게 발굴하고 철저한 경험주의에 입각하여 투자
            </ContentGray>
          ) : (
            <ContentGray>
              객관적인 분석을 통하여 미래성장동력이 풍부한 분야를 새롭게 발굴하고 철저한 경험주의에 입각하여 투자
            </ContentGray>
          )}

          {isMobile ? (
            <ContentBlack>
              캐피탈원은 2009년에 설립된 창업투자회사입니다.
              <br /> 캐피탈원은 세상을 바꿀 미래 기술, 아이디어, <br />
              인력 및 의지를 가진 Dreamer들을 지원합니다
            </ContentBlack>
          ) : (
            <ContentBlack>
              캐피탈원은 2009년에 설립된 창업투자회사입니다. 캐피탈원은 세상을 바꿀 <br />
              미래 기술, 아이디어, 인력 및 의지를 가진 Dreamer들을 지원합니다
            </ContentBlack>
          )}
        </Background>
      </a>
    </>
  );
};

export default AboutUs;

const Background = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding-bottom: 25.5rem;
  background: center no-repeat;
  @media (max-width: 480px) {
    background: center no-repeat;
    padding-bottom: 8rem;
  }
`;

const Header = styled.div`
  font-family: 'Inter';
  font-weight: 300;
  color: ${palette.Black};
  font-size: 2.8rem;
  text-align: left;
  margin: 5.7rem 0 0 5.7rem;
  @media (max-width: 480px) {
    width: 10rem;
    font-size: 1.8rem;
    margin: 2.6rem 0 0 2rem;
  }
`;
const ImageWrapper = styled.div`
  width: 141.1rem;
  height: 100%;
  margin: 11.2rem auto 0 auto;
`;

const CircleContainer = styled.img`
  max-width: 100%;
  height: auto;
  @media (max-width: 480px) {
    display: none;
  }
`;

const ContentBox = styled.div`
  margin: 2rem auto;
  min-width: 28rem;
  height: 36.3rem;
`;

const ContentGray = styled.div`
  text-align: center;
  font-weight: 500;
  color: ${palette.Darkgray};
  font-size: 2.8rem;
  font-family: 'Spoqa Han Sans Neo';
  margin-top: 6.6rem;
  @media (max-width: 480px) {
    font-size: 1.4rem;
    margin-top: 0rem;
  }
`;
const ContentBlack = styled.div`
  margin-top: 3.2rem;
  text-align: center;
  font-weight: 500;
  color: ${palette.Black};
  font-size: 2.8rem;
  font-family: 'Spoqa Han Sans Neo';
  @media (max-width: 480px) {
    font-size: 1.4rem;
  }
`;
