import Home from './pages/Home';
import AboutUs from './pages/AboutUs.js';
import History from './pages/History';
import Portfolio from './pages/Portfolio';
import Contact from './pages/Contact';
import Footer from './components/Footer';
import './App.css';

function App() {
  return (
    <>
      <Home />
      <AboutUs />
      <History />
      <Portfolio />
      <Contact />
      <Footer />
    </>
  );
}

export default App;
